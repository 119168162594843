import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from '../components/Header/Header';
import MuiAlert from '@mui/material/Alert';
import { connect, useDispatch } from 'react-redux';
import { Snackbar } from '@mui/material';
import { removeMessage } from '../redux/rootReducer';
import Modals from '../components/Modals/Modals';
import { pages } from './pages';

const mapStateToProps = state => {
    return {
        session: state.session,
        message: state.message,
        modal: state.modal
    }
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MainContainer({ session, message, modal }) {

    const dispatch = useDispatch();

    const hasWindow = typeof window !== 'undefined';

    const [width, setWidth] = React.useState(null);

    function getDimensions() {
        setWidth(hasWindow ? window.innerWidth : null);
    }

    React.useEffect(() => {
        getDimensions();
        window.addEventListener("resize", getDimensions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(removeMessage());
    };
    const handleExited = () => {
        dispatch(removeMessage());
    };

    return (
        <>
            <Header session={session} screenWidth={width}/>
            <Routes>
                {pages.map((page, i) => {
                    return <Route key={'page'+i} path={page.path} element={page.component} />
                })};
            </Routes>

            <Modals modal={modal} />

            {(message && Object.keys(message).length > 0) &&
                <Snackbar
                    open={Object.keys(message).length > 0}
                    autoHideDuration={3500}
                    TransitionProps={{ onExited: handleExited }}
                    onClose={handleClose}
                    anchorOrigin={
                        message.type === 'warning' ?
                            { vertical: 'center', horizontal: 'center' } :
                            (message.position ?
                                { vertical: 'bottom', horizontal: message.position }
                                :
                                { vertical: 'bottom', horizontal: 'left' }
                            )
                    }
                >
                    <Alert onClose={handleClose} className='alert-whx' severity={message.type} sx={{ width: '100%' }}>
                        {message.text}
                    </Alert>
                </Snackbar>
            }
        </>
    );
}

export default connect(mapStateToProps)(MainContainer);
