export const translations = {
    en: {
        translation: {
            "Promote": "Promote",
            "AddCoin": "Add your coin",
            'WTF is this?': 'WTF is this?',
            'Top Zombie Picks': 'Top Zombie Picks',
            'Win limited edition coin': 'Win limited edition coin',
            'Promoted stuff': 'Promoted stuff',
            'How to get your ad here': 'How to get your ad here',
            'Get your coin here!': 'Get your coin here!',
            'Project': 'Project',
            'Symbol': 'Symbol',
            'Launch': 'Launch',
            'Telegram': 'Telegram',
            'Whitelist': 'Whitelist',
            'Access': 'Access',
            'Votes': 'Votes',
            "Today's Presale": "Today's Presale",
            "Today's Launch": "Today's Launch",
            "Upcoming Presales": "Upcoming Presales",
            'Presale Platform': 'Presale Platform',
            'Launch Platform': 'Launch Platform',
            'Launch Date': 'Launch Date',
            'Upvote': 'Upvote',
            'Launched': 'Launched',
            'About CoinZombies': 'About CoinZombies',
            'Coin Info': 'Coin Info',
            'Presale & Launch': 'Presale & Launch',
            'Links': 'Links',
            'Contacts': 'Contacts',
            'List your coin': 'List your coin',
            "All steps completed - you're finished": "All steps completed - you're finished",
            "Coin Name": "Coin Name",
            "Description": "Description",
            "Chain": "Chain",
            "Contract Address": "Contract Address",
            "Whitelist Link": "Whitelist Link",
            "Presale Time": "Presale Time",
            "Your telegram": "Your telegram",
            'Your email': 'Your email',
            'Project Logo': 'Project Logo',
            'Discord Link': 'Discord Link',
            'Twitter Link': 'Twitter Link',
            'Btok Link': 'Btok Link',
            'Telegram China': 'Telegram China',
            'Telegram Link': 'Telegram Link',
            'Website Link': 'Website Link',
            'Launch Link': 'Launch Link',
            "Launch Time in UTC": "Launch Time in UTC",
            "Presale Link": "Presale Link",
            "Coin Zombies - Your Gateway to the Asian Crypto Community": "Coin Zombies - Your Gateway to the Asian Crypto Community",
            "Crypto loves Asia and so do Zombies, so naturally, Coin Zombies is popular with many Asian base crypto investors looking to buy into western tokens and projects.": "Crypto loves Asia and so do Zombies, so naturally, Coin Zombies is popular with many Asian base crypto investors looking to buy into western tokens and projects.",
            "Grow you project in Asia with Zombies,": "Grow you project in Asia with Zombies,",
            "Or don't, its up to you.": "Or don't, its up to you.",
            "See promotion options": "See promotion options",
            "Promote with Zombies!": "Promote with Zombies!",
            "Your project on Asian markets": "Your project on Asian markets",
            "Ad banner": "Ad banner",
            "Header Banner": "Header Banner",
            "1 day promotion": "1 day promotion",
            "3 days promotion": "3 days promotion",
            "7 days promotion": "7 days promotion",
            "Size": "Size",
            "File format": "File format",
            "File size": "File size",
            "up to": "up to",
            "Front Banner Small": "Front Banner Small",
            "Front Banner Large": "Front Banner Large",
            "Pop-up Banner": "Pop-up Banner",
            "Promoted Coin": "Promoted Coin",
            "CoinZombies Email Blast": "CoinZombies Email Blast",
            "blast": "blast",
            "blasts": "blasts",
            "Content format": "Content format",
            "Text or HTML with images": "Text or HTML with images",
            "Mail newsletter size": "Mail newsletter size",
            "approx 600px wide": "approx 600px wide",
            'Website': 'Website',
            'English': 'English'
        }
    },
    jp: {
        translation: {
            "Promote": "促進",
            "AddCoin": "コインを追加",
            'WTF is this?': 'これは何ですか?',
            'Top Zombie Picks': 'トップゾンビピック',
            'Win limited edition coin': '限定版コインを獲得',
            'Promoted stuff': '宣伝されたもの',
            'How to get your ad here?': '広告の入手方法はこちら?',
            'Get your coin here!': 'ここでコインを入手!',
            'Project': '計画',
            'Symbol': 'シンボル',
            'Launch': '発売',
            'Telegram': 'Telegram',
            'Whitelist': 'ホワイトリスト',
            'Access': 'アクセス',
            'Votes': '投票',
            "Today's Presale": "本日のプレセール",
            "Today's Launch": "本日の打ち上げ",
            "Upcoming Presales": "今後のプレセール",
            'Presale Platform': 'プレセールプラットフォーム',
            'Launch Platform': '打ち上げプラットフォーム',
            'Launch Date': '発売日',
            'Upvote': '賛成票を投じる',
            'Launched': '発売',
            'About CoinZombies': '約 CoinZombies',
            'Coin Info': 'コイン情報',
            'Presale & Launch': 'プレセール＆ローンチ',
            'Links': 'リンク',
            'Contacts': '連絡先',
            'List your coin': 'コインをリストする',
            "All steps completed - you're finished": "すべての手順が完了しました - これで完了です",
            "Coin Name": "コイン名",
            "Description": "説明",
            "Chain": "鎖",
            "Contract Address": "契約住所",
            "Whitelist Link": "ホワイトリスト リンク",
            "Presale Time": "先行販売時間",
            "Your telegram": "君の Telegram",
            'Your email': 'あなたの電子メール',
            'Project Logo': 'プロジェクトのロゴ',
            'Discord Link': 'Discord リンク',
            'Twitter Link': 'Twitter リンク',
            'Btok Link': 'Btok リンク',
            'Telegram China': 'Telegram 中国',
            'Telegram Link': 'Telegram リンク',
            'Website Link': 'ウェブサイト リンク',
            'Launch Link': '発売 リンク',
            "Launch Time in UTC": "発売 UTC の時間",
            "Presale Link": "プレセール リンク",
            "Coin Zombies - Your Gateway to the Asian Crypto Community": "Coin Zombies - アジアの暗号コミュニティへのゲートウェイ",
            "Crypto loves Asia and so do Zombies, so naturally, Coin Zombies is popular with many Asian base crypto investors looking to buy into western tokens and projects.": "クリプトはアジアを愛し、ゾンビもそうであるため、当然のことながら、コイン ゾンビは、欧米のトークンやプロジェクトへの購入を検討している多くのアジアのベース クリプト投資家に人気があります。",
            "Grow you project in Asia with Zombies,": "プロジェクトをアジアで成長させる と Zombies,",
            "Or don't, its up to you.": "やらないかはあなた次第.",
            "See promotion options": "プロモーション オプションを見る",

            //new
            "Promote with Zombies!": "ゾンビで宣伝！",
            "Your project on Asian markets": "アジア市場でのあなたのプロジェクト",
            "Ad banner": "広告バナー",
            "Header Banner": "ヘッダーバナー",
            "1 day promotion": "1日プロモーション",
            "3 days promotion": "3日間のプロモーション",
            "7 days promotion": "7日間のプロモーション",
            "Size": "サイズ",
            "File format": "ファイル形式",
            "File size": "ファイルサイズ",
            "up to": "まで",
            "Front Banner Small": "フロントバナー小",
            "Front Banner Large": "フロントバナー大",
            "Pop-up Banner": "ポップアップバナー",
            "Promoted Coin": "プロモートコイン",
            "CoinZombies Email Blast": "CoinZombies メールブラスト",
            "blast": "爆発",
            "blasts": "爆風",
            "Content format": "コンテンツ形式",
            "Text or HTML with images": "画像付きのテキストまたは HTML",
            "Mail newsletter size": "メールマガジンサイズ",
            "approx 600px wide": "幅約600px",
            'Website': 'Webサイト',
            'English': '英語'
        }
    },
    kr: {
        translation: {
            "Promote": "홍보하다",
            "AddCoin": "코인 추가",
            'WTF is this?': '이게 뭔가요?',
            'How to get your ad here?': '여기에 광고를 게재하는 방법은 무엇입니까?',
            'Top Zombie Picks': '최고의 좀비 추천',
            'Win limited edition coin': '한정판 코인 획득',
            'Promoted stuff': '판촉물',
            'Get your coin here!': '여기에서 코인을 받으세요!',
            'Project': '프로젝트',
            'Symbol': '상징',
            'Launch': '시작하다',
            'Telegram': 'Telegram',
            'Whitelist': '화이트리스트',
            'Access': '입장',
            'Votes': '투표',
            "Today's Presale": "오늘의 프리세일",
            "Today's Launch": "오늘의 출시",
            "Upcoming Presales": "예정된 사전 판매",
            'Presale Platform': '사전 판매 플랫폼',
            'Launch Platform': '플랫폼 출시',
            'Launch Date': '출시일',
            'Upvote': '찬성',
            'Launched': '출시',
            'About CoinZombies': '에 대한 CoinZombies',
            'Coin Info': '코인 정보',
            'Presale & Launch': '사전 판매 및 출시',
            'Links': '연결',
            'Contacts': '콘택트 렌즈',
            'List your coin': '코인 나열',
            "All steps completed - you're finished": "모든 단계가 완료되었습니다. 완료되었습니다.",
            "Coin Name": "코인 이름",
            "Description": "설명",
            "Chain": "체인",
            "Contact Address": "계약 주소",
            "Whitelist Link": "화이트리스트 링크",
            "Presale Time": "사전 판매 시간",
            "Your telegram": "당신의 telegram",
            'Your email': '귀하의 이메일',
            'Project Logo': '프로젝트 로고',
            'Discord Link': 'Discord 링크',
            'Twitter Link': 'Twitter 링크',
            'Btok Link': 'Btok 링크',
            'Telegram China': 'Telegram 중국',
            'Telegram Link': 'Telegram 링크',
            'Website Link': '웹 사이트 링크',
            'Launch Link': '링크 실행',
            "Launch Time in UTC": "시작 시간(UTC)",
            "Presale Link": "프리세일 링크",
            "Coin Zombies - Your Gateway to the Asian Crypto Community": "코인 좀비 - 아시아 암호화 커뮤니티로 가는 관문",
            "Crypto loves Asia and so do Zombies, so naturally, Coin Zombies is popular with many Asian base crypto investors looking to buy into western tokens and projects.": "Crypto는 아시아를 사랑하고 Zombies도 좋아하므로 자연스럽게 Coin Zombies는 서양 토큰 및 프로젝트를 구매하려는 많은 아시아 기반 암호화 투자자에게 인기가 있습니다.",
            "Grow you project in Asia with Zombies,": "좀비와 함께 아시아에서 성장 프로젝트,",
            "Or don't, its up to you.": "또는하지 마십시오. 그것은 당신에게 달려 있습니다.",
            "See promotion options": "프로모션 옵션 보기",

            //new
            "Promote with Zombies!": "좀비와 함께 홍보하세요!",
            "Your project on Asian markets": "아시아 시장에서의 프로젝트",
            "Ad banner": "광고 배너",
            "Header Banner": "헤더 배너",
            "1 day promotion": "1일 프로모션",
            "3 days promotion": "3일 프로모션",
            "7 days promotion": "7일 프로모션",
            "Size": "크기",
            "File format": "파일 형식",
            "File size": "파일 크기",
            "up to": "까지",
            "Front Banner Small": "전면 배너 소형",
            "Front Banner Large": "전면 배너 대형",
            "Pop-up Banner": "팝업 배너",
            "Promoted Coin": "프로모션 코인",
            "CoinZombies Email Blast": "CoinZombies 이메일 폭발",
            "blast": "폭발",
            "blasts": "폭발",
            "Content format": "콘텐츠 형식",
            "Text or HTML with images": "이미지가 있는 텍스트 또는 HTML",
            "Mail newsletter size": "메일 뉴스레터 크기",
            "approx 600px wide": "약 600px 너비",
            'Website': '웹사이트',
            'English': '영어'
        }
    },
    cn: {
        translation: {
            "Promote": "推动",
            "AddCoin": "添加你的硬币",
            'WTF is this?': '这是什么鬼？',
            'Top Zombie Picks': '僵尸首选',
            'Win limited edition coin': '赢得限量版硬币',
            'Promoted stuff': '推广的东西',
            'How to get your ad here?': '如何在此处获取您的广告',
            'Get your coin here!': '在这里拿你的硬币！',
            'Project': '项目',
            'Symbol': '象征',
            'Launch': '发射',
            'Telegram': 'Telegram',
            'Whitelist': '白名单',
            'Access': '使用权',
            'Votes': '投票',
            "Today's Presale": "今天的预售",
            "Today's Launch": "今天的发布",
            "Upcoming Presales": "即将到来的预售",
            'Presale Platform': '预售平台',
            'Launch Platform': '发射平台',
            'Launch Date': '发射日期',
            'Upvote': '点赞',
            'Launched': '推出',
            'About CoinZombies': '关于 CoinZombies',
            'Coin Info': '硬币信息',
            'Presale & Launch': '预售和发布',
            'Links': '链接',
            'Contacts': '联系人',
            'List your coin': '列出你的硬币',
            "All steps completed - you're finished": "所有步骤已完成 - 你完成了",
            "Coin Name": "硬币名称",
            "Description": "描述",
            "Chain": "链",
            "Contact Address": "合约地址",
            "Whitelist Link": "白名单链接",
            "Presale Time": "预售时间",
            "Your telegram": "您的 telegram",
            'Your email': '你的邮件',
            'Project Logo': '项目标志',
            'Discord Link': 'Discord 关联',
            'Twitter Link': 'Twitter 关联',
            'Btok Link': 'Btok 关联',
            'Telegram China': 'Telegram 中国',
            'Telegram Link': 'Telegram 关联',
            'Website Link': '网站链接',
            'Launch Link': '启动链接',
            "Launch Time in UTC": "UTC 启动时间",
            "Presale Link": "预售链接",
            "Coin Zombies - Your Gateway to the Asian Crypto Community": "Coin Zombies - 您通往亚洲加密社区的门户",
            "Crypto loves Asia and so do Zombies, so naturally, Coin Zombies is popular with many Asian base crypto investors looking to buy into western tokens and projects.": "Crypto 喜欢亚洲，Zombies 也喜欢，所以 Coin Zombies 很自然地受到许多希望购买西方代币和项目的亚洲基础加密投资者的欢迎。",
            "Grow you project in Asia with Zombies,": "与僵尸一起在亚洲发展您的项目，",
            "Or don't, its up to you.": "还是不要，这取决于你。",
            "See promotion options": "查看促销选项",


            "Promote with Zombies!": "与僵尸一起推广！",
            "Your project on Asian markets": "您在亚洲市场的项目",
            "Ad banner": "广告横幅",
            "Header Banner": "标题横幅",
            "1 day promotion": "1天促销",
            "3 days promotion": "3天促销",
            "7 days promotion": "7天促销",
            "Size": "尺寸",
            "File format": "文件格式",
            "File size": "文件大小",
            "up to": "取决于",
            "Front Banner Small": "正面横幅小",
            "Front Banner Large": "正面横幅大",
            "Pop-up Banner": "弹出式横幅",
            "Promoted Coin": "推广硬币",
            "CoinZombies Email Blast": "CoinZombies 电子邮件爆炸",
            "blast": "爆破",
            "blasts": "爆炸声",
            "Content format": "内容格式",
            "Text or HTML with images": "带有图像的文本或 HTML",
            "Mail newsletter size": "邮件通讯大小",
            "approx 600px wide": "约 600 像素宽",
            'Website': '网站',
            'English': '英语'
        }
    }
};