import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import banner1 from '../../assets/images/banners/banner1.png';
import banner2 from '../../assets/images/banners/banner2.png';
import banner3 from '../../assets/images/banners/banner3.png';
import { useTranslation } from 'react-i18next';

export default function Advertise() {
    const { t } = useTranslation();
    return (
        <Container maxWidth="lg">
            <Box className="container">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid className="AdvertiseContainer" container>
                        <Grid item xs={6}>
                            <Box className="flex column">
                                <Typography className="typography h" sx={{ position: 'relative', left: '30px', top: '10px' }}>{t('Top Zombie Picks')}</Typography>
                                <img className="img" src={banner1} alt={t('Win limited edition coin')} />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className="flex column">
                                <Typography className="typography link" sx={{ marginLeft: 'auto', position: 'relative', right: '30px', top: '10px' }}>
                                    {t('How to get your ad here?')}
                                </Typography>
                                <img className="img" src={banner2} alt="Space dogs" />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="flex">
                                <img className="img" src={banner3} alt="Binance coin" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}
