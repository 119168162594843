import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import telegram from '../../assets/images/socials/telegram.png'
import { ReactComponent as Logo } from '../../assets/images/Logo.svg';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/rootReducer';
import { useTranslation } from 'react-i18next';

export default function Footer({ color, transition }) {

    const dispatch = useDispatch();

    const handleAddCoin = (e) => {
        e.preventDefault();
        dispatch(showModal('listing'));
    };

    const { t } = useTranslation();

    const hasWindow = typeof window !== 'undefined';

    const [width, setWidth] = React.useState(null);

    function getDimensions() {
        setWidth(hasWindow ? window.innerWidth : null);
    }

    React.useEffect(() => {
        getDimensions();
        window.addEventListener("resize", getDimensions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box className='footer' style={{ backgroundColor: color }} sx={transition ? { paddingTop: '140px', paddingBottom: '60px' } : {}}>
            {transition &&
                <Box className='transition top'>
                    {/* <Transition /> */}
                    <img className="img" alt="" src={transition} />
                </Box>
            }
            <Box className="flex center" sx={{ display: 'flex', gap: '22px', marginLeft: {xs: '0', sm: '55px', md: '55px', lg: '55px'} }}>


                <Button onClick={handleAddCoin} className="btn btn-borderless"><span>{width > 1130 ? t('AddCoin') : t('Add')}</span></Button>
                <Link to='/promote'>
                    <Button className="btn btn-third"><span>{t('Promote')}</span></Button>
                </Link>
                <Link to='/about'>
                    <Button className="btn btn-borderless"><span>{width > 1130 ? t('About CoinZombies') : t('About')}</span></Button>
                </Link>
                <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' } }}>
                    <a href="https://telegram.com" className='flex center typography' >
                        <img className="header-img" src={telegram} alt="Our telegram account" style={width > 1130 ? { marginRight: '13px' } : { marginRight: '50px' }} />
                        <span style={{ position: 'relative', top: '2px' }}>{width > 1130 && 'Zombiegram'}</span>
                    </a>
                </Box>
            </Box>
            <Box className="logo">
                <Logo />
            </Box>
        </Box>
    )
}
