import { COINS, COINS_REMOVE } from '../types'

const initialState = [];
export const coinsReducer = (state = [], action) =>{
  const {type, payload} = action

  switch (type) {

    case COINS: {
        const { data } = payload
        return data
    }
    case COINS_REMOVE: {
      return initialState;
    }
    default: {
      return state
    }
  }
}
