import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import PrimaryTable from '../PrimaryTable/PrimaryTable';

import Transition from '../../assets/images/background/transition1.svg';

import { useSelector, useDispatch } from 'react-redux';
import { showModal } from '../../redux/rootReducer';

import { useTranslation } from 'react-i18next';

export default function Promoted() {

    const coins = useSelector(state => state.coins);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const handleAddCoin = (e) => {
        e.preventDefault();
        console.log(e)
        dispatch(showModal('listing'));
    };


    return (
        <Box sx={{background: "transparent url("+ Transition +") no-repeat top center"}}>
            <Container maxWidth="xl" sx={{ pt: 4}}>
                <Box className="container">
                    <Box sx={{ flexGrow: 1 }}>
                        <Box className='flex column' sx={{px: {xs: 2, sm: 2, md: 0, lg: 0}}}>
                            <Box className="flex items-center" sx={{ gap: '50px' }}>
                                <Typography className='typography h'>{t('Promoted stuff')}</Typography>
                                <Typography onClick={handleAddCoin} className='typography link'>{t('Get your coin here!')}</Typography>
                            </Box>
                            <Box>
                                <PrimaryTable coins={coins}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
