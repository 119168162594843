import React from 'react';
import Box from '@mui/material/Box';
import Advertise from '../../components/Advertise/Advertise';
import Promoted from '../../components/Promoted/Promoted';

import background from '../../assets/images/background/background1.png';
import background2 from '../../assets/images/background/background2.png';
import Footer from '../../components/Footer/Footer';
import Coins from '../../components/Coins/Coins';

import { Container } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';

import CoinDetails from '../../components/CoinDetails/CoinDetails';
import { useRef } from 'react';

function Coin() {

    const location = useLocation();
    const navigate  = useNavigate();
    const ref  = useRef();

    const [coin, setCoin] = React.useState(null);
    const [elemHeight, setElemHeight] = React.useState(0);

    React.useEffect(() => {
        window.scrollTo(0,0);
        if(location?.state?.coin){
            setCoin(location.state.coin);
        } else{
            navigate('/');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    React.useEffect(() => {
        if(ref?.current && coin){
            setElemHeight(ref.current.offsetHeight);
            console.log(ref.current)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, coin]);

    React.useEffect(() => {
        console.log(elemHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elemHeight]);

    return (
        <>  
            <img alt="" src={background} className="background" style={{height: '110vh'}}/>
            <Container ref={ref} sx={{left: 0, right: 0, position: 'absolute', maxWidth: '1200px', pt: 4, zIndex: 4}}>
                <CoinDetails coin={coin}/>
            </Container>

            <Box sx={elemHeight > 0 ? {marginBottom: (elemHeight / 2) + 'px'} : {}}/>
            
            <Box className="wrap" style={elemHeight > 0 ? {paddingTop: (elemHeight / 2) + 'px'} : {}} sx={{background: "transparent url("+ background2 +") no-repeat top center", backgroundSize: 'cover'}}>
                <Advertise/>
            </Box>
            <Footer color={'#000'}/>
        </>
    );
}

export default Coin;

