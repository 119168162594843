import React from 'react';
import Box from '@mui/material/Box';
import Advertise from '../../components/Advertise/Advertise';
import Promoted from '../../components/Promoted/Promoted';

import background from '../../assets/images/background/background1.png';
import background2 from '../../assets/images/background/background2.png';
import Footer from '../../components/Footer/Footer';
import Coins from '../../components/Coins/Coins';

import { useDispatch } from 'react-redux';
import { getCoins } from '../../redux/actions/coinActions';

function Main() {

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getCoins());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>  
            <img alt="" src={background} className="background"/>
            <Advertise/>
            <Box className="wrap" sx={{background: "transparent url("+ background2 +") no-repeat top center", backgroundSize: 'cover'}}>
                <Promoted />
            </Box>

            <Box className="wrap">
                <Coins />
            </Box>
            <Footer color={'#000'}/>
        </>
    );
}

export default Main;

