import * as React from 'react';
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Logo from '../../assets/images/Logo.svg'
import telegram from '../../assets/images/socials/telegram.png'
import { ReactComponent as UK } from '../../assets/images/icons/svg/uk.svg'
import Sparkle from 'react-sparkle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReactCountryFlag from "react-country-flag"
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/rootReducer';

import { i18n, withTranslation } from 'react-i18next';

const settings = [
  {
    // icon: 'DeleteIcon',
    name: 'Logout',
    link: '/logout'
  }
];

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));



const Header = ({ screenWidth, session, t, i18n }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElLang, setAnchorElLang] = React.useState(null);
  const [currentLang, setCurrentLang] = React.useState(i18n?.language ? i18n.language : 'en');

  const [loginUser, setLoginUser] = React.useState(false);
  const dispatch = useDispatch();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickLangMenu = (event) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorElLang(null);
  };

  const handleAddCoin = (e) => {
    e.preventDefault();
    dispatch(showModal('listing'));
  };

  const handleChangeLanguage = (e, lang) => {
    e.preventDefault();
    i18n.changeLanguage(lang);
    setCurrentLang(lang);
    setAnchorElLang(null);
  };

  return (
    <AppBar position="static" enableColorOnDark style={{ background: 'var(--navbar-color-bg)', padding: '15px' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ justifyContent: { xs: "space-between" } }}>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: 'white' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar" anchorEl={anchorElNav} keepMounted open={Boolean(anchorElNav)} onClose={handleCloseNavMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
              className="menu mobile"
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Button onClick={handleAddCoin} className="btn btn-primary"><span>{t('AddCoin')}</span></Button>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to='/promote'>
                  <Button className="btn btn-secondary"><span>{t('Promote')}</span></Button>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to='/about'>
                  <Button className="btn btn-secondary"><span>{t('WTF is this?')}</span></Button>
                </Link>
              </MenuItem>
            </Menu>
          </Box>

          {/* LOGO */}
          <Link to='/'>
            <Typography
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'flex' },
                marginLeft: { sm: "100px", md: "40px" },
                flexGrow: 1,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <Avatar sx={{ display: { md: 'flex' }, mr: 0.5, height: '100%', width: '100%' }} variant="square" src={Logo} />
            </Typography>
          </Link>

          <Box className="flex center" sx={{ display: { xs: 'none', md: 'flex' }, gap: '22px', marginLeft: '55px' }}>

            <Button onClick={handleAddCoin} className="btn btn-primary"><span>{screenWidth > 1200 ? t('AddCoin') : t("Add")}</span></Button>

            <Link to='/promote'>
              <Button className="btn btn-secondary"><span>{t('Promote')}</span></Button>
            </Link>
            <Link to='/about'>
              <Button className="btn btn-secondary"><span>{screenWidth > 1200 ? t('WTF is this?') : t("About")}</span></Button>
            </Link>

          </Box>
          <Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block"}}}>
            <a style={{ marginLeft: '8px' }} href="https://telegram.com" className='flex center typography telegram'>
              <img className="header-img" src={telegram} alt="Our telegram account" style={{ marginRight: '13px' }} />
              <span style={{ position: 'relative', top: '2px' }}>{screenWidth > 1200 && 'Zombiegram'}</span>
            </a>
          </Box>

          <Box sx={{ display: { md: 'flex' }, marginRight: { sm: "20px" } }}>
            <Button
              id="basic-button"
              className='btn btn-menu'
              aria-controls={Boolean(anchorElLang) ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorElLang) ? 'true' : undefined}
              onClick={handleClickLangMenu}
              startIcon={
                currentLang === 'en' ? <ReactCountryFlag
                  countryCode="US"
                  svg
                  style={{
                    width: '1.5em',
                    height: '1.5em',
                    borderRadius: '50%',
                    objectFit: 'cover'
                  }}
                  title="English"
                /> :
                  currentLang === 'kr' ? <ReactCountryFlag
                    countryCode="KR"
                    svg
                    style={{
                      width: '1.5em',
                      height: '1.5em',
                      borderRadius: '50%',
                      objectFit: 'cover'
                    }}
                    title="Korean"
                  /> :
                    currentLang === 'jp' ? <ReactCountryFlag
                      countryCode="JP"
                      svg
                      style={{
                        width: '1.5em',
                        height: '1.5em',
                        borderRadius: '50%',
                        objectFit: 'cover'
                      }}
                      title="Japanese"
                    /> :
                      currentLang === 'cn' && <ReactCountryFlag
                        countryCode="CN"
                        svg
                        style={{
                          width: '1.5em',
                          height: '1.5em',
                          borderRadius: '50%',
                          objectFit: 'cover',
                          objectPosition: 'top left'
                        }}
                        title="China"
                      />
              }
              endIcon={<KeyboardArrowDownIcon />}
            >
              <span className="text">{currentLang === 'en' && 'English'}</span>
              <span className="text">{currentLang === 'kr' && '한국어'}</span>
              <span className="text">{currentLang === 'jp' && '日本語'}</span>
              <span className="text">{currentLang === 'cn' && '中文'}</span>
            </Button>
            <Menu
              id="basic-menu"
              className='menu header'
              anchorEl={anchorElLang}
              open={Boolean(anchorElLang)}
              onClose={handleCloseLangMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {currentLang !== 'en' &&
                <MenuItem className="item" onClick={(e) => handleChangeLanguage(e, 'en')}>
                  <ReactCountryFlag
                    countryCode="US"
                    svg
                    style={{
                      width: '1.5em',
                      height: '1.5em',
                      borderRadius: '50%',
                      objectFit: 'cover'
                    }}
                    title="English"
                  />
                  <span>English</span>
                </MenuItem>
              }
              {currentLang !== 'cn' &&
                <MenuItem className="item" onClick={(e) => handleChangeLanguage(e, 'cn')}>
                  <ReactCountryFlag
                    countryCode="CN"
                    svg
                    style={{
                      width: '1.5em',
                      height: '1.5em',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      objectPosition: 'top left'
                    }}
                    title="China"
                  />
                  <span>中文</span>
                </MenuItem>
              }
              {currentLang !== 'kr' &&
                <MenuItem className="item" onClick={(e) => handleChangeLanguage(e, 'kr')}>
                  <ReactCountryFlag
                    countryCode="KR"
                    svg
                    style={{
                      width: '1.5em',
                      height: '1.5em',
                      borderRadius: '50%',
                      objectFit: 'cover'
                    }}
                    title="China"
                  />
                  <span>한국어</span>
                </MenuItem>
              }
              {currentLang !== 'jp' &&
                <MenuItem className="item" onClick={(e) => handleChangeLanguage(e, 'jp')}>
                  <ReactCountryFlag
                    countryCode="JP"
                    svg
                    style={{
                      width: '1.5em',
                      height: '1.5em',
                      borderRadius: '50%',
                      objectFit: 'cover'
                    }}
                    title="Japanese"
                  />
                  <span>日本語</span>
                </MenuItem>
              }
            </Menu>
          </Box>

          {/* AUTHORIZATION */}

          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip disableHoverListener>
                <Stack direction="row" spacing={1}>
                  { session.access_token ?
                    <Chip
                    onClick={handleOpenUserMenu}

                    avatar={<Avatar alt={session.user.username + ' avatar'} src={session.avatar && process.env.REACT_APP_BACKEND_URL + "/" + session.avatar.imageSmall}/>}
                    label={session.user.username}
                    variant="outlined"
                    />
                    :
                    <RegistrationModal register={setLoginUser}/>
                  }
                </Stack>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (

                    <Link to={setting.link} key={setting.name}>
                      <MenuItem
                      onClick={handleCloseUserMenu}
                      sx={{ fontSize: '13px', color: 'white', display: 'block', fontWeight: 'bold', maxWidth: '220px', display: 'flex', textTransform: 'uppercase' }}>
                        {setting.name === 'logout' ? <LogoutIcon/> : ''}
                        &emsp;{setting.name}
                      </MenuItem>
                  </Link>
              ))}
            </Menu>
          </Box> */}

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default withTranslation()(Header);
