import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { hideModal, setErrors, setMessage } from '../../redux/rootReducer';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import CheckIcon from '@mui/icons-material/Check';
import { headers } from '../../redux/config';
import { API_SEND_COIN_REVIEW } from '../../utils/consts';
import { MenuItem, Select } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import { useTranslation } from 'react-i18next';

export default function ListingModal() {

    const { t } = useTranslation();

    const steps = [t('Coin Info'), t('Presale & Launch'), t('Links'), t('Contacts')];

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [open, setOpen] = React.useState(true);
    const [error, setError] = React.useState(null);

    const [inputs, setInputs] = React.useState({
        coinName: '',
        symbol: '',
        description: '',
        chain: '',
        contactAddress: '',
        whiteListLink: '',
        presaleTime: '',
        presalePlatform: '',
        presaleLink: '',
        launchTime: '',
        launchPlatform: '',
        launchLink: '',
        websiteLink: '',
        telegramLink: '',
        telegramChina: '',
        btokLink: '',
        twitterLink: '',
        discordLink: '',
        projectLogo: '',
        email: '',
        telegram: '',
    });

    const requireds1 = ['coinName', 'symbol', 'description', 'chain', 'contactAddress'];
    const requireds2 = ['whiteListLink'];
    const requireds3 = ['telegramLink'];
    const requireds4 = ['email', 'telegram'];

    function handleChange(event) {
        const value = event.target.value;
        setInputs({
            ...inputs,
            [event.target.name]: value
        });
    }

    const dispatch = useDispatch();

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        dispatch(hideModal('listing'));
        setInputs({
            coinName: '',
            symbol: '',
            description: '',
            chain: '',
            contactAddress: '',
            whiteListLink: '',
            presaleTime: '',
            presalePlatform: '',
            presaleLink: '',
            launchTime: '',
            launchPlatform: '',
            launchLink: '',
            websiteLink: '',
            telegramLink: '',
            telegramChina: '',
            btokLink: '',
            twitterLink: '',
            discordLink: '',
            projectLogo: '',
            email: '',
            telegram: '',
        });
        setOpen(false);
    }

    const isStepOptional = (step) => {
        return step === 9999;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        setError(null);
        let stop = false;
        setTimeout(() => {
            if (activeStep === 0) {
                Object.keys(inputs).reverse().map((keyName, index) => {
                    requireds1.map((required) => {
                        if (keyName === required) {
                            if (inputs[keyName] === '' || inputs[keyName] === null) {
                                setError(keyName);
                                stop = true;
                                return '';
                            }
                        }
                    });
                })
            }
            if (stop)
                return;
            if (activeStep === 1) {
                Object.keys(inputs).reverse().map((keyName, index) => {
                    requireds2.map((required) => {
                        if (keyName === required) {
                            if (inputs[keyName] === '' || inputs[keyName] === null) {
                                setError(keyName);
                                stop = true;
                                return;
                            }
                        }
                    });
                })
            }
            if (stop)
                return;
            if (activeStep === 2) {
                Object.keys(inputs).reverse().map((keyName, index) => {
                    requireds3.map((required) => {
                        if (keyName === required) {
                            if (inputs[keyName] === '' || inputs[keyName] === null) {
                                setError(keyName);
                                stop = true;
                                return;
                            }
                        }
                    });
                })
            }
            if (stop)
                return;
            if (activeStep === 3) {
                Object.keys(inputs).reverse().map((keyName, index) => {
                    requireds4.map((required) => {
                        if (keyName === required) {
                            if (inputs[keyName] === '' || inputs[keyName] === null) {
                                setError(keyName);
                                stop = true;
                                return;
                            }
                        }
                    });
                })
            }
            if (stop)
                return;

            if (activeStep === steps.length - 1) {
                handleSend();
            }

            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }, 10);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleSend = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + API_SEND_COIN_REVIEW, {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    name: inputs.coinName,
                    name_symbol: inputs.symbol,
                    description: inputs.description,
                    chain_name: inputs.chain,
                    contract: inputs.contactAddress,
                    whitelist: inputs.whiteListLink,
                    presale: {
                        presale_time: inputs.presaleTime,
                        presale_url: inputs.presaleLink,
                        platform: {
                            name: inputs.presalePlatform
                        }
                    },
                    launch: {
                        launch_time: inputs.launchTime,
                        launch_url: inputs.launchLink,
                        platform: {
                            name: inputs.launchPlatform
                        }
                    },
                    access:  {
                        website: inputs.websiteLink,
                        discord: inputs.discordLink,
                        telegram: inputs.telegramLink,
                        telegram_cn: inputs.telegramChina,
                        twitter: inputs.twitterLink,
                        btok: inputs.btokLink
                    },
                    projectLogo: inputs.projectLogo,
                    email: inputs.email,
                    telegram: inputs.telegram
                })
            })

            if (response.status === 200) {
                const data = await response.json()
                dispatch(setMessage(data.message, data.type))
                return true;
            } else {
                const data = await response.json()
                dispatch(setErrors(data.errors))
                return true;
            }

        } catch (error) {
            dispatch(setErrors(error));
            return false;
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal listing">
                <IconButton className='BackButton'
                    onClick={handleClose}>
                    <ClearIcon />
                </IconButton>
                <Typography className="typography xlg font EffraBold">{t('List your coin')}</Typography>
                <Stepper className="stepper" activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography className="typography xxh" sx={{ mt: 4, mb: 1 }}>
                            {t("All steps completed - you're finished")}
                        </Typography>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {activeStep === 0 &&
                            <Box className='flex column center form'>
                                <Box className="control">
                                    <InputLabel className='label typography' required>{t("Coin Name")}</InputLabel>
                                    <TextField hiddenLabel required className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        style={error === 'coinName' ? { border: '2px solid red' } : {}}
                                        name="coinName"
                                        value={inputs.coinName}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography' required>{t("Symbol")}</InputLabel>
                                    <TextField hiddenLabel required className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        style={error === 'symbol' ? { border: '2px solid red' } : {}}
                                        name="symbol"
                                        value={inputs.symbol}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography' required>{t("Description")}</InputLabel>
                                    <TextField hiddenLabel required className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="description"
                                        value={inputs.description}
                                        style={error === 'description' ? { border: '2px solid red' } : {}}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography' required>{t("Chain")}</InputLabel>
                                    <Select hiddenLabel required className="input select" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="chain"
                                        value={inputs.chain}
                                        style={error === 'chain' ? { border: '2px solid red' } : {}}
                                    >
                                        <MenuItem value={'BSC'}>BSC</MenuItem>
                                        <MenuItem value={'ETH'}>ETH</MenuItem>
                                        <MenuItem value={'MATIC'}>MATIC</MenuItem>
                                        <MenuItem value={'AVAX'}>AVAX</MenuItem>
                                        <MenuItem value={'SOL'}>SOL</MenuItem>
                                        <MenuItem value={'Dogechain'}>Dogechain</MenuItem>
                                        <MenuItem value={'FTM'}>FTM</MenuItem>
                                        <MenuItem value={'ETHW'}>ETHW</MenuItem>
                                        <MenuItem value={'Arbitrum'}>Arbitrum</MenuItem>
                                        <MenuItem value={'ADA'}>ADA</MenuItem>
                                        <MenuItem value={'CRO'}>CRO</MenuItem>
                                        <MenuItem value={'Metis'}>Metis</MenuItem>
                                        <MenuItem value={'MOVR'}>MOVR</MenuItem>
                                        <MenuItem value={'CELO'}>CELO</MenuItem>
                                        <MenuItem value={'Harmony'}>Harmony</MenuItem>
                                        <MenuItem value={'Klaytn'}>Klaytn</MenuItem>
                                        <MenuItem value={'OKC'}>OKC</MenuItem>
                                        <MenuItem value={'Milkomeda'}>Milkomeda</MenuItem>
                                        <MenuItem value={'Enecuum'}>Enecuum</MenuItem>
                                        <MenuItem value={'Algorand'}>Algorand</MenuItem>
                                    </Select>
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography' required>{t("Contract Address")}</InputLabel>
                                    <TextField hiddenLabel required className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="contactAddress"
                                        value={inputs.contactAddress}
                                        style={error === 'contactAddress' ? { border: '2px solid red' } : {}}
                                    />
                                </Box>
                            </Box>
                        }

                        {activeStep === 1 &&
                            <Box className='flex column center form'>
                                <Box className="control">
                                    <InputLabel className='label typography' required>{t("Whitelist Link")}</InputLabel>
                                    <TextField hiddenLabel required className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="whiteListLink"
                                        value={inputs.whiteListLink}
                                        style={error === 'whiteListLink' ? { border: '2px solid red' } : {}}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography'>{t("Presale Time")}</InputLabel>
                                    <TextField hiddenLabel className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="presaleTime"
                                        value={inputs.presaleTime}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography'>{t("Presale Platform")}</InputLabel>
                                    <Select hiddenLabel className="input select" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="presalePlatform"
                                        value={inputs.presalePlatform}
                                        style={error === 'chain' ? { border: '2px solid red' } : {}}
                                    >
                                        <MenuItem value={'None'}>{t("None")}</MenuItem>
                                        <MenuItem value={'DxSale'}>DxSale</MenuItem>
                                        <MenuItem value={'Unicrypt'}>Unicrypt</MenuItem>
                                        <MenuItem value={'Astronaut'}>Astronaut</MenuItem>
                                        <MenuItem value={'GemPad'}>GemPad</MenuItem>
                                        <MenuItem value={'Oxbull'}>Oxbull</MenuItem>
                                        <MenuItem value={'PinkSale'}>PinkSale</MenuItem>
                                        <MenuItem value={'Kommunitas'}>Kommunitas</MenuItem>
                                        <MenuItem value={'Other'}>Other</MenuItem>
                                    </Select>
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography'>{t("Presale Link")}</InputLabel>
                                    <TextField hiddenLabel className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="presaleLink"
                                        value={inputs.presaleLink}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography' >{t("Launch Time in UTC")}</InputLabel>
                                    <TextField hiddenLabel className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="launchTime"
                                        value={inputs.launchTime}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography'>{t("Launch Platform")}</InputLabel>
                                    <Select hiddenLabel className="input select" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="launchPlatform"
                                        value={inputs.launchPlatform}
                                        style={error === 'chain' ? { border: '2px solid red' } : {}}
                                    >
                                        <MenuItem value={'None'}>{t("None")}</MenuItem>
                                        <MenuItem value={'PancakeSwap'}>PancakeSwap</MenuItem>
                                        <MenuItem value={'UniSwap'}>UniSwap</MenuItem>
                                        <MenuItem value={'TraderJoe'}>TraderJoe</MenuItem>
                                        <MenuItem value={'DogeSwap'}>DogeSwap</MenuItem>
                                        <MenuItem value={'QuickSwap'}>QuickSwap</MenuItem>
                                        <MenuItem value={'Biswap'}>Biswap</MenuItem>
                                        <MenuItem value={'MM Finance'}>MM Finance</MenuItem>
                                        <MenuItem value={'SpookySwap'}>SpookySwap</MenuItem>
                                        <MenuItem value={'Crodex'}>Crodex</MenuItem>
                                        <MenuItem value={'Other'}>Other</MenuItem>
                                    </Select>
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography'>{t("Launch Link")}</InputLabel>
                                    <TextField hiddenLabel className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="launchLink"
                                        value={inputs.launchLink}
                                    />
                                </Box>
                            </Box>
                        }

                        {activeStep === 2 &&
                            <Box className='flex column center form'>
                                <Box className="control">
                                    <InputLabel className='label typography'>{t("Website Link")}</InputLabel>
                                    <TextField hiddenLabel className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="websiteLink"
                                        value={inputs.websiteLink}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography' required>{t("Telegram Link")}</InputLabel>
                                    <TextField hiddenLabel className="input" required variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="telegramLink"
                                        value={inputs.telegramLink}
                                        style={error === 'telegramLink' ? { border: '2px solid red' } : {}}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography'>{t("Telegram China")}</InputLabel>
                                    <TextField hiddenLabel className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="telegramChina"
                                        value={inputs.telegramChina}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography'>{t("Btok Link")}</InputLabel>
                                    <TextField hiddenLabel required className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="btokLink"
                                        value={inputs.btokLink}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography'>{t("Twitter Link")}</InputLabel>
                                    <TextField hiddenLabel required className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="twitterLink"
                                        value={inputs.twitterLink}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography'>{t("Discord Link")}</InputLabel>
                                    <TextField hiddenLabel required className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="discordLink"
                                        value={inputs.discordLink}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography'>{t("Project Logo")}</InputLabel>
                                    <TextField hiddenLabel required className="input" variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="projectLogo"
                                        value={inputs.projectLogo}
                                    />
                                </Box>
                            </Box>
                        }
                        {activeStep === 3 &&
                            <Box className='flex column center form'>
                                <Box className="control">
                                    <InputLabel className='label typography' required>{t("Your email")}</InputLabel>
                                    <TextField hiddenLabel required className="input" variant="filled" size="medium" type="email" onChange={handleChange}
                                        name="email"
                                        value={inputs.email}
                                        style={error === 'email' ? { border: '2px solid red' } : {}}
                                    />
                                </Box>
                                <Box className="control">
                                    <InputLabel className='label typography' required>{t("Your telegram")}</InputLabel>
                                    <TextField hiddenLabel className="input" required variant="filled" size="medium" type="text" onChange={handleChange}
                                        name="telegram"
                                        value={inputs.telegram}
                                        style={error === 'telegram' ? { border: '2px solid red' } : {}}
                                    />
                                </Box>
                            </Box>
                        }


                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt: 3 }}>
                            {activeStep !== 0 &&
                                <Button
                                    color="inherit"
                                    className="btn btn-third"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                            }
                            <Box sx={{ flex: '1 1 auto' }} />
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Skip
                                </Button>
                            )}

                            <Button className="btn btn-fourth" onClick={handleNext}>
                                {activeStep === steps.length - 1 ? <><CheckIcon sx={{ mr: 1.5, ml: -1.5 }} /> Send for Review</> : 'Next'}
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </Modal>
    );
}
