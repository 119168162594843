import React from 'react'
import { Box, Typography, Grid, Tooltip, Button, IconButton, Grow, Fade } from '@mui/material';


import telegram from '../../assets/images/socials/telegram.png';

import { ReactComponent as Person } from '../../assets/images/icons/svg/person.svg';
import binance from '../../assets/images/icons/png/binance.png';
import pancake from '../../assets/images/icons/png/pancake.png';
import chrome from '../../assets/images/socials/chrome.svg';

import like from '../../assets/images/icons/png/like.png';
import unicorn from '../../assets/images/icons/png/unicorn.png';
import twitter from '../../assets/images/icons/png/twitter.png';
import website from '../../assets/images/icons/png/website.png';

import moment from 'moment';

import { ReactComponent as Fire } from '../../assets/images/icons/svg/today.svg';
import { ReactComponent as Presale } from '../../assets/images/icons/svg/upcoming.svg';
import { ReactComponent as Launch } from '../../assets/images/icons/svg/launch.svg';

import { useDispatch } from 'react-redux';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { setMessage } from '../../redux/rootReducer';
import { useTranslation } from 'react-i18next';

export default function CoinDetails({ coin }) {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const copyToClipboard = () => {
        if (coin.contract) {
            navigator.clipboard.writeText(coin?.contract);
            dispatch(setMessage('Text copied to clipboard'));
        } else {
            return;
        }
    };

    const handleClick = () => {
        alert('TODO: upvode')
    };
    
    const [loaded, setLoaded] = React.useState(false);

    if (!coin)
        return <></>;

    return (
        <>
            <Box className="CoinDetails">
                <Box className="header">
                    <Box className="top">
                        <Grid container spacing={2}>
                            <Grid item xs={2.7} style={{ maxWidth: 'unset' }}>
                                <Box className='flex' sx={{ gap: '25px' }}>
                                    <img alt="currency" className="thumbnail" src={coin?.image} />
                                    <Box className='flex column'>
                                        <Typography className='typography large'>
                                            {coin?.name}
                                        </Typography>
                                        <Box className="currency">
                                            <Typography className='typography'>{coin.name_symbol}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={1.7} sm={5} md={2.5} lg={1.7} className="flex items-center">
                                <Box className="flex telegram" sx={{ gap: "8px" }}>
                                    <Tooltip arrow title={coin?.telegram}>
                                        <a target="_blank" rel="noreferrer" href={coin?.telegram}>
                                            <img src={telegram} className="img" alt="telegram channel" />
                                        </a>
                                    </Tooltip>
                                    <Box className="flex column">
                                        <Typography className='typography md'>
                                            12003 <Person style={{ marginLeft: '5px' }} />
                                        </Typography>
                                        <Typography className='typography sm yellow' sx={{display: {xs: "none", sm: 'block', md: 'block', lg: "block"}}}>
                                            2 days ago
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item className="flex items-center" sx={{maxWidth: {xs: "53%", sm: '66%', md: 'unset', lg: "unset"}, paddingLeft: {xs: 'unset !important', sm: 'unset !important'}, marginLeft: {xs: "15px", sm: '15px', md: 'auto', lg: 'auto'}}}>
                                <Box className="network" sx={{width: '100%', overflow: 'hidden'}}>
                                    <Typography className="typography">ETH: <span style={{ marginLeft: '10px', width: 'fit-content', maxWidth: '480px', overflow: 'hidden', minWidth: '450px', paddingRight: '40px' }}>{coin?.contract}</span></Typography>
                                    <IconButton onClick={copyToClipboard} style={{ background: '#011B31', marginLeft: '15px', position: 'absolute', right: '-2px', top: '3px' }}><ContentCopyIcon /></IconButton>
                                </Box>
                            </Grid>
                            <Grid item sx={{ marginLeft: 'auto', paddingTop: 'unset', display: 'flex', alignItems: 'center' }}>
                                <Button onClick={handleClick} className="btn btn-yellow">
                                    <img src={like} style={{ width: '24px', height: '24px' }} alt="like" />
                                    <span>{t("Upvote")}!</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="middle" sx={{flexDirection: {xs: "column", sm: 'column', md: 'row', lg: "row"}}}>
                        <Box className='flex'>
                            <Typography className="typography">{t("Presale Platform")}:</Typography>
                            <Typography className="typography primary" sx={{ ml: 1.5 }}>{coin.presale?.platform?.name ? coin.presale.platform.name : "None"}</Typography>
                        </Box>
                        <Box className='flex'>
                            <Typography className="typography">{t("Launch Platform")}:</Typography>
                            <Typography className="typography primary" sx={{ ml: 1.5 }}>{coin.launch?.platform?.name ? coin.launch.platform.name : "None"}</Typography>
                        </Box>
                        <Box className='flex'>
                            <Typography className="typography">{t("Launch Date")}:</Typography>
                            <Typography className="typography primary" sx={{ ml: 1.5, textTransform: "capitalize" }}>{coin.launch?.launch_time ? moment(coin.launch.launch_time).fromNow() : t("Launched")}</Typography>
                            <Launch style={{ marginLeft: '10px' }} />
                        </Box>
                    </Box>
                    <Box className="bottom">
                        <Typography className="typography">{coin.description}</Typography>
                        <Box className="socials">

                            {coin?.access?.website &&
                                <Grow in timeout={200}>
                                    <a target="_blank" className="btn btn-blue flex items-center" rel="noreferrer" href={coin?.access?.website}><img className='access' alt="website" src={website} /><span>{t('Website')}</span></a>
                                </Grow>
                            }
                            {coin?.access?.telegram_cn &&
                                <Grow in timeout={200}>
                                    <a target="_blank" className="btn btn-blue flex items-center" rel="noreferrer" href={coin?.access?.telegram_cn}><span>{t('Telegam')}</span></a>
                                </Grow>
                            }
                            {coin?.access?.telegram &&
                                <Grow in timeout={200}>
                                    <a target="_blank" className="btn btn-blue flex items-center" rel="noreferrer" href={coin?.access?.telegram}><span>Telegram {t('English')}</span></a>
                                </Grow>
                            }
                            {coin?.access?.twitter &&
                                <Grow in timeout={200}>
                                    <a target="_blank" className="btn btn-blue flex items-center" rel="noreferrer" href={coin?.access?.twitter}><img className='access' alt="twitter" src={twitter} /><span>{t('Twitter')}</span></a>
                                </Grow>
                            }
                            {coin?.access?.pancake &&
                                <Grow in timeout={200}>
                                    <a target="_blank" className="btn btn-blue flex items-center" rel="noreferrer" href={coin?.access?.pancake}><img className='access' alt="website" src={pancake} /><span>PancakeSwap</span></a>
                                </Grow>
                            }
                            {coin?.launch &&
                                <Grow in timeout={200}>
                                    <a target="_blank" className="btn btn-blue nobg flex items-center" rel="noreferrer" href={coin?.launch?.launch_url}><img className='access' alt="website" src={unicorn} /><span>{t('Launch Platform')}</span></a>
                                </Grow>
                            }
                        </Box>
                    </Box>
                </Box>
                {coin?.chain_name && coin?.chain_name !== '' && coin?.contract && coin?.contract !== '' &&
                    <Box className="details">
                        <Box className="flex items-center" sx={{ gap: '15px', paddingBottom: '15px', paddingLeft: '10px' }}>
                            <img alt="currency" className="thumbnail" src={coin?.image} />
                            <Typography className="typography large" sx={{ position: 'relative', top: '2px', textTransform: 'uppercase' }}>{coin.name}</Typography>
                            <Typography className="typography primary" sx={{ position: 'relative', top: '4px', fontSize: '18px' }}>{coin.name_symbol}</Typography>
                        </Box>
                        <Fade in={loaded} timeout={200}>
                            <iframe
                                title={coin.name}
                                src={"https://dexscreener.com/" + coin.chain_name + '/' + coin.contract}
                                scrolling="no"
                                onLoad={() => setLoaded(true)}
                                style={{ width: "100%", height: "570px" }}
                                class="iframe-dexcharts" />
                        </Fade>
                    </Box>
                }
            </Box>
        </>
    )
}
