import React from 'react'
import { Box, Container, Button, Typography, Fade } from '@mui/material';

import map from '../../assets/images/about_map.png';

import Transition from '../../assets/images/background/transition1.svg';
import Footer from '../../components/Footer/Footer';
import { useTranslation } from 'react-i18next';

export default function About() {

    const { t } = useTranslation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Box className='wrap' sx={{ background: 'transparent linear-gradient(180deg, #430630 0%, #3E1935 100%) 0% 0% no-repeat padding-box' }}>
                <Container maxWidth="md">
                    <Box className="flex column" sx={{ padding: {xs: '50px 20px', sm: '50px 20px', md: '50px 0', lg: '50px 0'}, position: 'relative' }}>
                        <Fade in timeout={1400}>
                            <Box sx={{ margin: '0 auto' }}>
                                <img src={map} alt="china map" style={{ width: '100%' }} />
                            </Box>
                        </Fade>

                        <Typography className="typography xxxlg font EffraMedium text-center">
                            {t("Coin Zombies - Your Gateway to the Asian Crypto Community")}
                        </Typography>
                        <Typography className="typography font EffraMedium text-center" sx={{ fontSize: '22px', marginTop: '30px' }}>
                        {t("Crypto loves Asia and so do Zombies, so naturally, Coin Zombies is popular with many Asian base crypto investors looking to buy into western tokens and projects.")}
                        </Typography>
                        <Typography className="typography font EffraMedium text-center" sx={{ fontSize: '22px', paddingTop: '50px', maxWidth: '400px', margin: '0 auto' }}>
                            {t("Grow you project in Asia with Zombies,")}
                            {t("Or don't, its up to you.")}
                        </Typography>

                    </Box>
                </Container>
            </Box>
            <Box sx={{ position: 'absolute', left: '0', right: '0', width: 'fit-content', margin: '0 auto', zIndex: 3 }}>
                <Button className="btn btn-primary">
                    <span>{t("See promotion options")}</span>
                </Button>
            </Box>
            <Footer color={'#062743'} transition={Transition} />
        </>
    )
}
