import React from 'react'
import { Box, Container, Typography, Fade, Grid, Card, CardMedia, CardContent, List, ListItem } from '@mui/material';

import Transition from '../../assets/images/background/transition1.svg';
import Footer from '../../components/Footer/Footer';
import { useTranslation } from 'react-i18next';

import adbanner1 from '../../assets/images/banners/adbanner1.png';
import adbanner2 from '../../assets/images/banners/adbanner2.png';
import adbanner3 from '../../assets/images/banners/adbanner3.png';
import adbanner4 from '../../assets/images/banners/adbanner4.png';
import adbanner5 from '../../assets/images/banners/adbanner5.png';
import adbanner6 from '../../assets/images/banners/adbanner6.png';

import promoteImg from '../../assets/images/promote.png';

export default function Promote() {

  const { t } = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box className='wrap' sx={{ background: 'transparent linear-gradient(180deg, #430630 0%, #3E1935 100%) 0% 0% no-repeat padding-box' }}>
        <Container maxWidth="md">
          <Box className="flex column" sx={{ padding: '50px 0', position: 'relative' }}>
            <Fade in timeout={1200}>
              <Box sx={{ margin: '0 auto' }}>
                <img src={promoteImg} alt="china map" style={{ width: '100%' }} />
              </Box>
            </Fade>

            <Typography className="typography xxxlg font EffraMedium text-center">
              {t("Promote with Zombies!")}
            </Typography>
            <Typography className="typography font EffraMedium text-center" sx={{ fontSize: '22px', marginTop: '30px' }}>
              {t("Your project on Asian markets")}
            </Typography>

            <Grid container className="promotions-grid">

              <Grid item xs>
                <Card>
                  <CardMedia
                    component="img"
                    height={272}
                    width={451}
                    image={adbanner1}
                    alt={t('Ad banner')}
                  />
                  <Box>
                    <CardContent>
                      <Typography className="typography" gutterBottom variant="h5" component="div" sx={{ marginBottom: 'unset' }}>
                        {t('Header Banner')}
                      </Typography>
                      <List>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('1 day promotion')} &nbsp; – &nbsp;<span className="typography primary">0.85 BNB</span></Typography>
                        </ListItem>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('3 days promotion')} – &nbsp;<span className="typography primary">2.2 BNB</span></Typography>
                        </ListItem>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('7 days promotion')} – &nbsp;<span className="typography primary">4.8 BNB</span></Typography>
                        </ListItem>
                      </List>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('Size:')} 1200 x 120px
                      </Typography>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('File format')}: .jpg .jpeg .png .gif .HTML5
                      </Typography>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('File size')}: {t('up to')} 8MB
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs>
                <Card>
                  <CardMedia
                    component="img"
                    height={272}
                    width={451}
                    image={adbanner2}
                    alt={t('Ad banner')}
                  />
                  <Box>
                    <CardContent>
                      <Typography className="typography" gutterBottom variant="h5" component="div" sx={{ marginBottom: 'unset' }}>
                        {t('Front Banner Small')}
                      </Typography>
                      <List>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('1 day promotion')} &nbsp; – &nbsp;<span className="typography primary">0.5 BNB</span></Typography>
                        </ListItem>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('3 days promotion')} – &nbsp;<span className="typography primary">1.2 BNB</span></Typography>
                        </ListItem>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('7 days promotion')} – &nbsp;<span className="typography primary">2.5 BNB</span></Typography>
                        </ListItem>
                      </List>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('Size:')} 385 x 240px
                      </Typography>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('File format')}: .jpg .jpeg .png .gif .HTML5
                      </Typography>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('File size')}: {t('up to')} 8MB
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs>
                <Card>
                  <CardMedia
                    component="img"
                    height={272}
                    width={421}
                    image={adbanner3}
                    alt={t('Ad banner')}
                  />
                  <Box>
                    <CardContent>
                      <Typography className="typography" gutterBottom variant="h5" component="div" sx={{ marginBottom: 'unset' }}>
                        {t('Front Banner Large')}
                      </Typography>
                      <List>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('1 day promotion')} &nbsp; – &nbsp;<span className="typography primary">1 BNB</span></Typography>
                        </ListItem>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('3 days promotion')} – &nbsp;<span className="typography primary">1.5 BNB</span></Typography>
                        </ListItem>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('7 days promotion')} – &nbsp;<span className="typography primary">2.5 BNB</span></Typography>
                        </ListItem>
                      </List>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('Size:')} 1200 x 330px
                      </Typography>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('File format')}: .jpg .jpeg .png .gif .HTML5
                      </Typography>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('File size')}: {t('up to')} 8MB
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs>
                <Card>
                  <CardMedia
                    component="img"
                    height={272}
                    width={451}
                    image={adbanner4}
                    alt={t('Ad banner')}
                  />
                  <Box>
                    <CardContent>
                      <Typography className="typography" gutterBottom variant="h5" component="div" sx={{ marginBottom: 'unset' }}>
                        {t('Pop-up Banner')}
                      </Typography>
                      <List>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('1 day promotion')} &nbsp; – &nbsp;<span className="typography primary">1 BNB</span></Typography>
                        </ListItem>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('3 days promotion')} – &nbsp;<span className="typography primary">1.5 BNB</span></Typography>
                        </ListItem>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('7 days promotion')} – &nbsp;<span className="typography primary">2.5 BNB</span></Typography>
                        </ListItem>
                      </List>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('Size:')} 385 x 240px
                      </Typography>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('File format')}: .jpg .jpeg .png .gif .HTML5
                      </Typography>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('File size')}: {t('up to')} 8MB
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs>
                <Card>
                  <CardMedia
                    component="img"
                    height={272}
                    width={451}
                    image={adbanner5}
                    alt={t('Ad banner')}
                  />
                  <Box>
                    <CardContent>
                      <Typography className="typography" gutterBottom variant="h5" component="div" sx={{ marginBottom: 'unset' }}>
                        {t('Promoted Coin')}
                      </Typography>
                      <List>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('1 day promotion')} &nbsp; – &nbsp;<span className="typography primary">0.4 BNB</span></Typography>
                        </ListItem>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('3 days promotion')} – &nbsp;<span className="typography primary">1 BNB</span></Typography>
                        </ListItem>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">{t('7 days promotion')} – &nbsp;<span className="typography primary">2 BNB</span></Typography>
                        </ListItem>
                      </List>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs>
                <Card>
                  <CardMedia
                    component="img"
                    height={272}
                    width={451}
                    image={adbanner6}
                    alt={t('Ad banner')}
                  />
                  <Box>
                    <CardContent>
                      <Typography className="typography" gutterBottom variant="h5" component="div" sx={{ marginBottom: 'unset' }}>
                        {t('CoinZombies Email Blast')}
                      </Typography>
                      <List>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">1 {t('blast')} &nbsp; – &nbsp;<span className="typography primary">2 BNB</span></Typography>
                        </ListItem>
                        <ListItem>
                          <Typography className="typography" variant='subtitle2' color="text.secondary">3 {t('blasts')} – &nbsp;<span className="typography primary">4 BNB</span></Typography>
                        </ListItem>
                      </List>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('Size:')} {t('Mail newsletter size')} ({t('approx 600px wide')})
                      </Typography>
                      <Typography className="typography" variant="body2" color="text.secondary">
                        {t('Content format')}: {t('Text or HTML with images')}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>

            </Grid>
          </Box>
        </Container>
      </Box>
      <Footer color={'#062743'} transition={Transition} />
    </>
  )
}
