export const initialState = {
  session: [],
  loadings: [],
  error: {},
  errors: [],
  message: {},
  modal: {},
  tab: '',
  coins: [],
  fcoins: []
}