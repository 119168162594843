import React from 'react'
import ListingModal from './ListingModal'

export default function Modals(modal) {

  return (
    <>
        {modal.modal.name === 'listing' && <ListingModal/>}
    </>
  )
}
