import * as React from 'react';

import { Box, Grid, Typography, Button, Fade, Tooltip } from '@mui/material';

import { ReactComponent as Fire } from '../../assets/images/icons/svg/today.svg';
import { ReactComponent as Presale } from '../../assets/images/icons/svg/upcoming.svg';
import { ReactComponent as Launch } from '../../assets/images/icons/svg/launch.svg';

import { useNavigate } from 'react-router-dom';

import telegram from '../../assets/images/socials/telegram.png';

import moment from 'moment';

import { ReactComponent as Person } from '../../assets/images/icons/svg/person.svg';
import binance from '../../assets/images/icons/png/binance.png';
import pancake from '../../assets/images/icons/png/pancake.png';
import twitter from '../../assets/images/socials/twitter.svg';
import chrome from '../../assets/images/socials/chrome.svg';
import { useTranslation } from 'react-i18next';

export default function PrimaryTable({ coins }) {

    const [tab, setTab] = React.useState("today");

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [currentCoins, setCurrentCoins] = React.useState([]);

    React.useEffect(() => {
        if (tab === 'today')
            setCurrentCoins(coins?.today_presale);
        if (tab === 'launch')
            setCurrentCoins(coins?.today_launch);
        if (tab === 'upcoming')
            setCurrentCoins(coins?.upcoming);
    }, [coins, tab]);

    React.useEffect(() => {
        if (tab === 'today')
            setCurrentCoins(coins?.today_presale);
        if (tab === 'launch')
            setCurrentCoins(coins?.today_launch);
        if (tab === 'upcoming')
            setCurrentCoins(coins?.upcoming);
    }, [coins, tab]);

    const navigateTo = (coin) => {
        navigate(
            '/coin',
            {
                state: {
                    coin
                }
            }
        );
    };

    return (
        <>
            <Box className="flex secondaryTableIcons" sx={{ gap: '5px' }}>
                <Button onClick={() => setTab('today')} className={"btn " + (tab === 'today' ? "btn-third" : "btn-third-borderless")}><Fire />&nbsp;{t("Today's Presale")}</Button>
                <Button onClick={() => setTab('launch')} className={"btn " + (tab === 'launch' ? "btn-third" : "btn-third-borderless")}><Launch />&nbsp;{t("Today's Launch")}</Button>
                <Button onClick={() => setTab('upcoming')} className={"btn " + (tab === 'upcoming' ? "btn-third" : "btn-third-borderless")}><Presale />&nbsp;{t("Upcoming Presales")}</Button>
            </Box>
            <Box className="secondaryTable flex column">
                <Box className="head" sx={{display: {xs: 'none', sm: 'none', md: 'flex', lg: "flex"}}}>
                    <Grid container>
                        <Grid item xs={2.2}>
                            <Typography className='typography'>{t('Project')}</Typography>
                        </Grid>
                        <Grid item xs={1.8}>
                            <Typography className='typography'>{t('Launch')}</Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Typography className='typography'>{t('Telegram')}</Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Typography className='typography'>{t('Whitelist')}</Typography>
                        </Grid>
                        <Grid item xs={2.4}>
                            <Typography className='typography'>{t('Access')}</Typography>
                        </Grid>
                        <Grid item xs={1.2}>
                            <Typography className='typography'>{t('Votes')}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="body">
                    {currentCoins?.map((coin, i) => {
                        console.log(coin)
                        return <Fade in timeout={500}>
                            <Box key={'pc' + i} className="cell" onClick={() => navigateTo(coin)}>
                                <Grid container>
                                    <Grid item xs={12} sm={8.5} md={2.2}>
                                        <Box className='flex' sx={{ gap: '25px' }}>
                                            <img alt="currency" className="thumbnail" src={coin?.image} />
                                            <Box className='flex column'>
                                                <Typography className='typography large'>
                                                    {coin?.name}
                                                </Typography>
                                                <Box className="currency">
                                                    <img alt="currency" src={coin?.coin_image} />
                                                    <Typography className='typography'>{coin.name_symbol}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1.8} sm={3.5} md={1.8} sx={{display: {xs: 'none', sm: 'flex', md: 'flex', lg: "flex"}}}>
                                        {coin?.launch?.launch_time &&
                                            <Typography className='typography'>{moment(coin?.launch?.launch_time).fromNow()}</Typography>
                                        }
                                    </Grid>
                                    <Grid item xs={5.5} sm={8} md={1.5} sx={{gap: "8px", display: {xs: 'flex', sm: 'flex', md: 'flex', lg: "flex"}, marginTop: {xs: '20px', sm: '20px', md: 'unset', lg: 'unset'}}}>
                                        <Box className="flex telegram" sx={{ gap: "8px" }}>
                                            <Tooltip arrow title={coin?.telegram}>
                                                <a target="_blank" rel="noreferrer" href={coin?.telegram}>
                                                    <img src={telegram} className="img" alt="telegram channel" />
                                                </a>
                                            </Tooltip>
                                            <Box className="flex column">
                                                <Typography className='typography md'>
                                                    12003 <Person style={{ marginLeft: '5px' }} />
                                                </Typography>
                                                <Typography className='typography sm yellow'>
                                                    2 days ago
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1.5} sx={{display: {xs: 'none', sm: 'none', md: 'flex', lg: "flex"}, marginTop: {xs: 'unset', sm: '20px', md: 'unset', lg: 'unset'}}}>
                                        <Box className="enabled" />

                                    </Grid>
                                    <Grid item xs={4} sm={4} md={2.4} sx={{gap: '5px', display: {xs: 'flex', sm: 'flex', md: 'flex', lg: "flex"}, marginTop: {xs: '20px', sm: '20px', md: 'unset', lg: 'unset'}}}>
                                        {coin?.access?.telegram &&
                                            <Tooltip arrow title={coin.telegram}>
                                                <a target="_blank" rel="noreferrer" href={coin?.access?.telegram}><img className='access' alt="telegram" src={telegram} /></a>
                                            </Tooltip>
                                        }
                                        {coin?.access?.telegram_cn &&
                                            <Tooltip arrow title={coin.telegram_cn}>
                                                <a target="_blank" rel="noreferrer" href={coin?.access?.telegram_cn}><img className='access' alt="telegram" src={telegram} /></a>
                                            </Tooltip>
                                        }
                                        {coin?.access?.website &&
                                            <Tooltip arrow title={coin.website}>
                                                <a target="_blank" rel="noreferrer" href={coin?.access?.website}><img className='access' alt="website" src={chrome} /></a>
                                            </Tooltip>
                                        }
                                        {coin?.access?.twitter &&
                                            <Tooltip arrow title={coin.twitter}>
                                                <a target="_blank" rel="noreferrer" href={coin?.access?.twitter}><img className='access' alt="twitter" src={twitter} /></a>
                                            </Tooltip>
                                        }
                                        {coin?.access?.pancake &&
                                            <Tooltip arrow title={coin.pancake}>
                                                <a target="_blank" rel="noreferrer" href={coin?.access?.pancake}><img className='access' alt="website" src={pancake} /></a>
                                            </Tooltip>
                                        }
                                    </Grid>
                                    <Grid item xs={1.2} sx={{display: {xs: 'none', sm: 'none', md: 'flex', lg: "flex"}}}>
                                        <Typography className="typography">{coin?.upvote}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Fade>
                    })}
                </Box>
            </Box>
        </>
    )
}
