import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import { rootReducer } from './rootReducer'
import { initialState } from './state'
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig={
    key: 'root',
    storage,
    whitelist: ['session', 'coins', 'fcoins']
}

const persistedReducer=persistReducer(persistConfig,rootReducer);
const store = createStore(persistedReducer, initialState, composeWithDevTools(applyMiddleware(thunk)));
const Persistor=persistStore(store);

export {store};
export {Persistor};
