import React from 'react'
import { Box, Container } from '@mui/material';
import SecondaryTable from '../SecondaryTable/SecondaryTable';
import Transition from '../../assets/images/background/transition2.svg';

import { useSelector } from 'react-redux';

export default function Coins() {

    const coins = useSelector(state => state.coins);
    
    React.useEffect(() => {
        
        console.log(coins)
    
      return () => {
        
      }
    }, [coins])
    
    return (
        <>
            <Box className='transition top'>
                {/* <Transition /> */}
                <img alt="" className="img" src={Transition} />
            </Box>
            <Box className="coins">
                <Container maxWidth="xl">
                    <Box className="container flex column" sx={{px: {xs: 2, sm: 2, md: 0, lg: 0}}}>
                        <SecondaryTable coins={coins}/>
                    </Box>
                </Container>
            </Box>
        </>
    )
}
