export const USER = 'USER';
export const USER_UPDATE = 'USER_UPDATE';

export const ERROR = 'ERROR';
export const ERRORS = 'ERRORS';

export const ERROR_REMOVE = 'ERROR_REMOVE';
export const ERRORS_REMOVE = 'ERRORS_REMOVE';

export const MODAL_ENABLE = 'MODAL_ENABLE';
export const MODAL_DISABLE = 'MODAL_DISABLE';

export const TAB = 'TAB';
export const TAB_REMOVE = 'TAB_REMOVE';

export const MESSAGES = 'MESSAGES';
export const MESSAGE = 'MESSAGE';
export const MESSAGE_REMOVE = 'MESSAGE_REMOVE';
export const MESSAGES_REMOVE = 'MESSAGES_REMOVE';

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_LOADING = "SET_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";

export const SET_LOADINGS = "SET_LOADINGS";
export const REMOVE_LOADINGS = "REMOVE_LOADINGS";

/////////////// NEW CONSTS

export const LANGUAGE = "LANGUAGE";

export const COINS = "COINS";
export const COINS_ADD_ONE = "COINS_ADD_ONE";
export const COINS_REMOVE = "COIN_REMOVE";
export const COINS_REMOVE_ONE = "COINS_REMOVE_ONE";

export const FCOINS = "FCOINS";
export const FCOINS_ADD_ONE = "FCOINS_ADD_ONE";
export const FCOINS_REMOVE = "FCOINS_REMOVE";
export const FCOINS_REMOVE_ONE = "FCOINS_REMOVE_ONE";