import { API_GET_COINS, API_SEND_COIN_REVIEW } from "../../utils/consts"
import { setError, setMessage } from "../rootReducer"
import { COINS, COINS_REMOVE } from "../types"
import { headers } from "../config";
// import axios from 'axios';

// const token = sessionStorage.getItem("access_token");

const setCoins = (data) => {
  return {
    type: COINS,
    payload: { data }
  }
}

// eslint-disable-next-line no-unused-vars
const removeCoins = (data) => {
  return {
    type: COINS_REMOVE,
    payload: { data }
  }
}

export const getCoins = () => async (dispatch) => {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + API_GET_COINS + '?offset=1&limit=15&type=categorized', {
      method: "GET",
      headers: headers
    })

    const data = await response.json();

    if(data.success)
        dispatch(setCoins(data.data));

  } catch (error) {
    dispatch(setError(error))
  }
}

export const addCoin = () => async (dispatch) => {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + API_SEND_COIN_REVIEW, {
      method: "GET",
      headers: headers
    })

    const data = await response.json();

    if(data.success)
        console.log(data)
        dispatch(setMessage(data.message));

  } catch (error) {
    dispatch(setError(error))
  }
}






