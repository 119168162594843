import { FCOINS, FCOINS_REMOVE } from '../types'

const initialState = [];
export const featuredCoinsReducer = (state = [], action) =>{
  const {type, payload} = action

  switch (type) {

    case FCOINS: {
        const { data } = payload
        return data
    }
    case FCOINS_REMOVE: {
      return initialState;
    }
    default: {
      return state
    }
  }
}
