import Logout from "../components/Logout/Logout"
import Main from "../pages/Main/Main"
import { Navigate } from 'react-router-dom';
import About from "../pages/About/About";
import Promote from "../pages/Promote/Promote";
import Coin from "../pages/Coin/Coin";

export const pages = [
    {
        path: '/',
        component: <Main />
    },
    {
        path: '/about',
        component: <About />
    },
    {
        path: '/coin',
        component: <Coin />
    },
    {
        path: '/promote',
        component: <Promote />
    },
    {
        path: '/login',
        component: <Main />
    },
    {
        path: '/logout',
        component: <Logout />
    },
    {
        path: '*',
        component: <Navigate to="/"/>
    }
];